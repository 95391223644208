<template>
  <el-container>
    <el-main>
      <nav-bar />
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
import NavBar from 'components/layout/NavBar'

export default {
  name: 'Index',
  components: {
    NavBar
  },
  data() {
    return {
      isCollapse: false,
      navList: [
        { path: '/my', name: '我的帐号', icon: 'el-icon-upload' }
      ]
    }
  },
  /*  watch: {
    // 地址栏 url 发生变化时重新加载本页面
    $route() {
      this.$router.go()
    }
  },*/
  created() {
    document.title = 'tnb'
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 800px;
}

#aside-style {
  min-width: 120px;
  max-width: 240px;
  width: 30%;
}
</style>
